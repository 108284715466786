import React from 'react';


export default function Projects() {
    return (
        <div className="Projects">
            <h2>👷 Projects</h2>
            <p>🚧 Coming soon</p>
        </div>
    )
}